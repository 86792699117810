import React from "react"
import { useState } from "react"
import Layout from "../components/layout"
import EmailForm from "../components/email-form.js"
import "../stylesheets/pages/lets-talk.css"
import Footer from "../components/footer.js"


export default function Contact() {
  return (
    <Layout>
      <div id="LTPage" className="mob">
        <div className="LTHeader mob">Let's Talk</div>
        <div className="LTtext mob">
          Interested in starting a project?<br/>
          Are you an amazing React or React Native developer?<br/>
          <strong>Get in touch.</strong>
        </div>
        <EmailForm id={1} containerId="form-container"/>
        <Footer color="white"/>
      </div>
    </Layout>
  )
}
